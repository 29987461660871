import React, { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import AliceCarousel from 'react-alice-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

import Grid from '@/components/atoms/Grid';
import Typography from '@/components/atoms/Typography';
import Button from '@/components/atoms/Button';
import Link from '@/components/atoms/Link';
import Background from '@/components/atoms/Background';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';

interface ItemProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  background?: ImageDataType;
  title?: React.ReactNode;
  link?: string;
}

export interface ShowcaseProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  items?: ItemProps[] | undefined;
}

const Title = styled(Typography)`
  text-align: center;

  ${mq('lg')} {
    text-align: left;
  }
`;
const Description = styled(Typography)`
  font-weight: 500;
  display: none;

  ${mq('lg')} {
    display: block;
  }
`;
const ParentGrid = styled(Grid)``;
const CarouselGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(3)};

  & .alice-carousel__stage-item {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }

  ${mq('lg')} {
    margin-top: 0;
  }
`;
const Prev = styled(Button)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  ${mq('lg')} {
    display: none;
  }
`;
const Next = styled(Button)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
const ShowcaseLink = styled(Link)`
  display: block;
`;
const ShowcaseBg = styled(Background)`
  width: 100%;
  max-width: 340px;
  height: 420px;
  border-radius: 10px;
`;
const ShowcaseTitle = styled(Typography)`
  position: absolute;
  bottom: 30px;
  left: 30px;
  max-width: 250px;
  transition: 0.3s ease;
`;
const Discover = styled(Typography)`
  transition: 0.3s ease;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 30px;
  left: 30px;
  transform: translateX(-100px);
`;
const DiscoverArrow = styled(FontAwesomeIcon)`
  fill: white;
  margin-left: ${({ theme }) => theme.spacing(1.5)};
`;
const ShowcaseRootHover = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover ${ShowcaseTitle} {
    transform: translateY(-40px);
  }

  &:hover ${Discover} {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
`;

const ShowcaseSlide = ({ background, title, link }: ItemProps): JSX.Element => {
  if (link) {
    return (
      <ShowcaseLink to={link} css={ShowcaseRootHover}>
        <ShowcaseBg background={background}>
          <ShowcaseTitle variant="h4" color="white">
            {title}
          </ShowcaseTitle>
          <Discover as="p" color="white">
            Découvrir <DiscoverArrow icon={faArrowRight} />
          </Discover>
        </ShowcaseBg>
      </ShowcaseLink>
    );
  }

  return (
    <div css={ShowcaseRootHover}>
      <ShowcaseBg background={background}>
        <ShowcaseTitle variant="h4" color="white">
          {title}
        </ShowcaseTitle>
        <Discover as="p" color="white">
          Découvrir <DiscoverArrow icon={faArrowRight} />
        </Discover>
      </ShowcaseBg>
    </div>
  );
};

const responsive = { 0: { items: 1 }, 768: { items: 2 } };

const Showcase = ({
  title,
  description,
  items,
}: ShowcaseProps): JSX.Element => {
  const handleDragStart = (e: React.MouseEvent) => e.preventDefault();
  const ShowcaseSlides = items?.map((item, index) => (
    <ShowcaseSlide
      onDragStart={handleDragStart}
      key={index.toString()}
      {...item}
    />
  ));

  return (
    <ParentGrid container>
      <Grid xxs={12} lg={4}>
        <Title variant="h2">{title}</Title>
        <Description>{description}</Description>
      </Grid>
      <CarouselGrid xxs={12} lg={8}>
        <AliceCarousel
          items={ShowcaseSlides}
          mouseTracking
          infinite
          disableDotsControls
          responsive={responsive}
          renderPrevButton={(): JSX.Element => (
            <Prev variant="text">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Prev>
          )}
          renderNextButton={(): JSX.Element => (
            <Next variant="text">
              <FontAwesomeIcon icon={faArrowRight} />
            </Next>
          )}
        />
      </CarouselGrid>
    </ParentGrid>
  );
};

export default Showcase;
