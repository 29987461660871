import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import Showcase from '@/components/molecules/Showcase';

import mq from '@/styles/mq';

import { ShowcaseProps } from '@/components/molecules/Showcase/Showcase';

interface BlogCategoriesProps {
  blogCategories: ShowcaseProps[];
}

const Root = styled.section``;

const BlogCategoriesRoot = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(5)};
  padding-bottom: ${({ theme }) => theme.spacing(5)};

  ${mq('lg')} {
    border-bottom: 1px solid #e0e0e0;
    margin-top: ${({ theme }) => theme.spacing(15)};
  }
`;

const BlogCategories = ({
  blogCategories,
}: BlogCategoriesProps): JSX.Element => {
  const ShowCase = blogCategories.map((item) => (
    <BlogCategoriesRoot maxWidth="lg">
      <Showcase
        title={item?.title}
        description={item?.description}
        items={item?.items}
      />
    </BlogCategoriesRoot>
  ));
  return <Root>{ShowCase}</Root>;
};

export const query = graphql`
  fragment BlogCategories on PrismicBlogPageDataType {
    categories {
      category {
        document {
          ... on PrismicBlogCategories {
            id
            data {
              title {
                raw
              }
              content {
                raw
              }
              articles {
                article {
                  document {
                    ... on PrismicArticlePage {
                      id
                      data {
                        title {
                          raw
                        }
                        hero_background {
                          url
                          alt
                        }
                      }
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogCategories;
