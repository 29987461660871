import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo/Seo';
import BlogContainer from '@/containers/Blog/BlogContainer';

import { BlogPageQuery } from '../../../graphql-types';

interface Props {
  data: BlogPageQuery;
}

export const pageQuery = graphql`
  query BlogPage {
    prismicBlogPage {
      data {
        meta_title
        meta_description
        ...BlogCategories
      }
    }
    allPrismicBlogPageBody1Companystats {
      edges {
        node {
          primary {
            type
          }
          items {
            number {
              raw
            }
            label {
              text
            }
          }
        }
      }
    }
    prismicBlogPageBodyContactbox {
      primary {
        title {
          raw
        }
        content {
          raw
        }
        button_name {
          raw
        }
        link
        image {
          url
          alt
        }
      }
    }
    allPrismicArticlePage(
      sort: { fields: data___publication_date, order: ASC }
      limit: 4
    ) {
      edges {
        node {
          uid
          data {
            ...LastArticles
          }
        }
      }
    }
    prismicCompanyStats {
      data {
        ...CompanyStats
      }
    }
  }
`;

const Blog = ({ data }: Props): JSX.Element => {
  const { meta_title, meta_description } = data?.prismicBlogPage?.data || {};

  return (
    <Layout invertNav type="magasin">
      <Seo
        title={meta_title || 'Blog'}
        description={meta_description || 'This is our blog'}
      />
      <BlogContainer data={data} type="magasin" />
    </Layout>
  );
};

export default Blog;
