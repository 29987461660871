import React, { HTMLAttributes } from 'react';
import styled from '@emotion/styled';

import Button from '@/components/atoms/Button';

interface Props extends Omit<HTMLAttributes<HTMLButtonElement>, 'color'> {
  label: string;
}

const TagRoot = styled(Button)`
  pointer-events: none;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const Tag = ({ label, ...rest }: Props): JSX.Element => {
  return (
    <TagRoot variant="contained" {...rest}>
      {label}
    </TagRoot>
  );
};

export default Tag;
