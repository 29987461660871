import React from 'react';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import ContactBox from '@/components/molecules/ContactBox';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';

interface Props {
  background?: ImageDataType;
  title?: React.ReactNode;
  content?: React.ReactNode;
}

const BlogContactBoxRoot = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(10)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(15)};
    margin-bottom: ${({ theme }) => theme.spacing(15)};
  }
`;

const BlogContactBox = (props: Props): JSX.Element => {
  return (
    <BlogContactBoxRoot maxWidth={1040}>
      <ContactBox {...props} />
    </BlogContactBoxRoot>
  );
};

export default BlogContactBox;
