import React from 'react';
import { RichText } from 'prismic-reactjs';

import BlogHero from '@/containers/Blog/BlogHero';
import BlogTrends from '@/containers/Blog/BlogTrends';
import BlogFormations from '@/containers/Blog/BlogFormations';
import BlogAdvices from '@/containers/Blog/BlogAdvices';
import BlogCategories from '@/containers/Blog/BlogCategories';
import BlogContactBox from '@/containers/Blog/BlogContactBox';
import BlogStats from '@/containers/Blog/BlogStats';

import {
  BlogPageQuery,
  PrismicCompanyStatsStatsGroupType,
} from '../../../graphql-types';

interface Props {
  data: BlogPageQuery;
  type: string;
}

const BlogContainer = ({ data, type }: Props): JSX.Element => {
  // Data

  const {
    // main_article,
    // articles,
    // trends_title,
    // trends_description,
    // trends,
    // formations_title,
    // formations_description,
    // formations,
    // advices_title,
    // advices_description,
    // advices,
    categories,
  } = data?.prismicBlogPage?.data || {};

  // const {
  //   contact_box_background,
  //   contact_box_section_title,
  //   contact_box_content,
  // } = data?.prismicContactBox?.data || {};

  //const { stats } = data?.prismicCompanyStats?.data || {};

  const { primary } = data?.prismicBlogPageBodyContactbox || {};

  const companyStats = data?.allPrismicBlogPageBody1Companystats?.edges;

  // const { hero_background: mainBg, title: mainTitle, content: mainContent } =
  //   main_article?.document?.data || {};

  // Props

  // const HeroProps = {
  //   main: {
  //     title: <RichText render={mainTitle?.raw} />,
  //     content: mainContent?.text,
  //     background: {
  //       url: mainBg?.url,
  //       alt: mainBg?.alt,
  //     },
  //   },
  //   articles: articles?.map((article: any) => {
  //     const { title: articleTitle, content: articleContent } =
  //       article?.article?.document?.data || {};

  //     return {
  //       title: <RichText render={articleTitle?.raw} />,
  //       content: articleContent?.text,
  //       uid: article?.article?.document.uid,
  //     };
  //   }),
  // };

  const LastArticles = data?.allPrismicArticlePage?.edges.map((el) => ({
    uid: el?.node?.uid,
    data: el?.node?.data,
  }));
  const MainArticle = LastArticles[0];
  const Articles = LastArticles.slice(1);

  const HeroProps = {
    main: {
      uid: MainArticle?.uid,
      title: MainArticle?.data?.title?.text,
      content: MainArticle?.data?.content?.text,
      background: {
        url: MainArticle?.data?.hero_background?.url,
        alt: MainArticle?.data?.hero_background?.alt,
      },
    },
    articles: Articles.map((el: any) => ({
      uid: el?.uid,
      title: el?.data?.title?.text,
      content: el?.data?.content?.text,
      background: {
        url: el?.data?.hero_background?.url,
        alt: el?.data?.hero_background?.alt,
      },
    })),
  };

  const BlogCategoriesProps = {
    blogCategories: categories?.map((el) => {
      const title = el?.category?.document?.data?.title?.raw;
      const content = el?.category?.document?.data?.content?.raw;
      const allArticles = el?.category?.document?.data?.articles;
      return {
        title: <RichText render={title} />,
        description: <RichText render={content} />,
        items: allArticles?.map((article) => ({
          title: (
            <RichText render={article?.article?.document?.data?.title?.raw} />
          ),
          background: {
            url: article?.article?.document?.data?.hero_background?.url,
            alt: article?.article?.document?.data?.hero_background?.alt,
          },
          link: article?.article?.document?.uid,
        })),
      };
    }),
  };

  // const TrendsProps = {
  //   title: <RichText render={trends_title?.raw} />,
  //   description: <RichText render={trends_description?.raw} />,
  //   items: trends?.map((trend: any) => {
  //     const { image, title, link } = trend || {};

  //     return {
  //       background: {
  //         url: image?.url,
  //         alt: image?.alt,
  //       },
  //       title: <RichText render={title?.raw} />,
  //       link: link?.url,
  //     };
  //   }),
  // };

  // const FormationsProps = {
  //   title: <RichText render={formations_title?.raw} />,
  //   description: <RichText render={formations_description?.raw} />,
  //   items: formations?.map((formation: any) => {
  //     const { image, title, link } = formation || {};

  //     return {
  //       background: {
  //         url: image?.url,
  //         alt: image?.alt,
  //       },
  //       title: <RichText render={title?.raw} />,
  //       link: link?.url,
  //     };
  //   }),
  // };

  // const AdvicesProps = {
  //   title: <RichText render={advices_title?.raw} />,
  //   description: <RichText render={advices_description?.raw} />,
  //   items: advices?.map((formation: any) => {
  //     const { image, title, link } = formation || {};

  //     return {
  //       background: {
  //         url: image?.url,
  //         alt: image?.alt,
  //       },
  //       title: <RichText render={title?.raw} />,
  //       link: link?.url,
  //     };
  //   }),
  // };

  // const ContactBoxProps = {
  //   background: {
  //     url: contact_box_background?.url,
  //     alt: contact_box_background?.alt,
  //   },
  //   title: <RichText render={contact_box_section_title?.raw} />,
  //   content: <RichText render={contact_box_content?.raw} />,
  // };

  // const StatsProps = {
  //   stats: stats?.map((el: PrismicCompanyStatsStatsGroupType | null) => ({
  //     number: <RichText render={el?.number?.raw} />,
  //     label: el?.label,
  //   })),
  // };

  const [StatsProps] = companyStats.map((el: any) => {
    if (el?.node?.primary?.type === type) {
      return {
        stats: el?.node?.items?.map((item: any) => ({
          number: <RichText render={item?.number?.raw} />,
          label: item?.label?.text,
        })),
      };
    }
    return {};
  });

  const ContactBoxProps = {
    title: <RichText render={primary?.title?.raw} />,
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
  };

  return (
    <>
      <BlogHero {...HeroProps} />
      <BlogCategories {...BlogCategoriesProps} />
      {/* <BlogTrends {...TrendsProps} /> */}
      {/* <BlogFormations {...FormationsProps} /> */}
      {/* <BlogAdvices {...AdvicesProps} /> */}
      {primary && <BlogContactBox {...ContactBoxProps} />}
      {companyStats.length > 0 && <BlogStats {...StatsProps} />}
    </>
  );
};

export default BlogContainer;
